isTest = false;
versionDate = "20200421:1950";

corsUrl = "https://4fqvgxfeyk.execute-api.ap-southeast-2.amazonaws.com/prod";
AWSConnect = "https://va-contactcentre.my.connect.aws/";
salesforceUrl = "https://visionaustralia.my.salesforce.com/";
agentToAgentCallNo = "+611800512443";
cognitoUrl = "https://vision-australia-app-prod.auth.ap-southeast-2.amazoncognito.com";
cognitoAppId = "31183111llr0eq64hjf4j7gba7";
cognitoAppSecret = "1vdu1oilmf5lt27nv30s8foj1ad54j75cdnsrc2qi0160oofluvj";

urlBase = {
  connect: AWSConnect,
  salesforce: salesforceUrl,
  fetchContactUrl: `${corsUrl}/getcontact`,
  fetchAccountUrl: `${corsUrl}/searchaccount`,
  fetchAgentUrl: `${corsUrl}/getagent`,
  updateAgentUrl: `${corsUrl}/updateagent`
};
configVision = {
  ccpUrl: urlBase.connect + "ccp-v2#",
  ccpLogin: "https://visionaustralia.okta.com/home/amazon_aws/0oanvt8djlrtychTE0x7/272?fromHome=true",
  //ccpLogout: `${cognitoUrl}/logout?client_id=${cognitoAppId}&logout_uri=${encodeURI(selfUrl)}`
  ccpLogout: `${urlBase.connect}/logout`
};

configOperata = {
  operataClientID: "a3b12f8f-08ae-4434-8f47-0737bdb79cda",
  operataClientSecret: "YhVctvIg8dwR3uM2",
  location: "Vision Australia",
  ccpLogCollection: "LOG"
};

setCcpUrl = function () {
  $("#ccpUrl").html(configVision.ccpUrl);
  $("#ccpUrl").data("url", configVision.ccpUrl);
  $("#loginbutton").attr("href", configVision.ccpLogin);
};
